<template>
	<div class="account-pages my-5 pt-5">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 col-xl-5">
					<div class="card overflow-hidden">
						<div class="bg-primary">
							<div class="text-primary text-center p-4">
								<h5 class="text-white font-size-20" id="loginTitle">Login</h5>
								<p class="text-white-50">Login to the instaGiv dashboard.</p>
								<router-link tag="a" to="/" class="logo logo-admin">
									<img src="@/assets/images/logo-sm.png" height="50" alt="logo" />
								</router-link>
							</div>
						</div>
						<div class="card-body p-4">
							<div class="pt-3 pl-3 pr-3">
								<b-alert
									v-model="isAuthError"
									variant="danger"
									class="mt-4 text-center"
									id="authError"
								>{{authError}}</b-alert>

								<b-form @submit.prevent="login" class="form-horizontal mt-4" id="loginForm">
									<b-form-group id="input-group-1" label="Email" label-for="email">
										<b-form-input
											id="email"
											v-model="email"
											type="email"
											placeholder="Enter email"
											:disabled="isLoggingIn"
											:class="{ 'is-invalid': $v.email.$error }"
											@input="$v.email.$touch"
											v-focus
										></b-form-input>
										<div class="invalid-feedback" v-if="!$v.email.required && $v.email.$dirty">Field is required</div>
										<div class="invalid-feedback" v-else-if="!$v.email.email && $v.email.$dirty">This is not a valid email</div>
									</b-form-group>

									<b-form-group
										id="input-group-2"
										label="Password"
										label-for="password"
										class="mb-3"
									>
										<b-form-input
											id="password"
											v-model="password"
											type="password"
											placeholder="Enter password"
											:disabled="isLoggingIn"
											:class="{ 'is-invalid': $v.password.$error }"
											@input="$v.password.$touch"
											@blur="$v.password.$touch"
										></b-form-input>
										<div class="invalid-feedback" v-if="!$v.password.required && $v.password.$dirty">Field is required</div>
									</b-form-group>

									<div class="form-group row">
										<div class="col-sm-12 text-center">
											<b-button variant="primary" class="w-md" id="loginBtn" type="submit" :disabled="isLoggingIn">
												<span v-if="!isLoggingIn">Login</span>
												<i v-else class="fas fa-spin fa-circle-notch"></i>
											</b-button>
										</div>
									</div>

									<div class="form-group mt-2 mb-0 row">
										<div class="col-12 text-center">
											<router-link tag="a" to="/request-reset">
												<i class="mdi mdi-lock"></i> Forgotten your password?
											</router-link>
										</div>
									</div>
								</b-form>
							</div>
						</div>
						<!-- end card-body -->
					</div>
					<!-- end card -->
					<div class="mt-3 text-center">
						<p>
							Don't have an account?
							<a class="font-weight-medium" href="https://signup.instagiv.com/">Register Now!</a>
						</p>
						<p class="mb-0">
							© {{new Date().getFullYear()}} instaGiv. All Rights Reserved.
						</p>
					</div>
				</div>
				<!-- end col -->
			</div>
			<!-- end row -->
		</div>
	</div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
	name: 'Login',
	page: {
			title: "Login"
	},
	components: {},
	data() {
		return {
			email: '',
			password: '',
			authError: null,
			isLoggingIn: false,
			isAuthError: false,
		};
	},
	methods: {
		login() {
			// Reset the authError if it existed.
			this.authError = null;
			this.isLoggingIn = true;

			this.$v.$touch()

			if (this.$v.$invalid) {
				this.isLoggingIn = false;
				this.authError = 'Please make sure all fields are filled in correctly.'
				this.isAuthError = true
				return
			}

			var data = {
				email: this.email,
				password: this.password
			}
		
			this.axios.post('sign-in/charity', {
					...data
				})
				.then((response) => {
					this.isLoggingIn = false;
					this.isAuthError = false;
					if (response.data.data.redirect === '/dashboard') { 
						// go to charity overview 
						window.location.href = process.env.VUE_APP_IG2V2_CHARITY_DASHBOARD_URL
					} else if (response.data.data.redirect === '/admin') { 
						// go to admin dashboard 
						window.location.href = process.env.VUE_APP_REACT_ADMIN_DASHBOARD_URL
					}
				})
				.catch((error) => {
					this.isLoggingIn = false;
					this.authError = (error.response && error.response.data.error_text) ? error.response.data.error_text : 'An unexpected error occurred. If the problem persists, please contact support.'
					this.isAuthError = true;
					// eslint-disable-next-line
					console.error('Error:', error);
				})
		}
	},
	validations: {
		email: { required, email },
		password: { required }
	},	
	directives: {
		focus: {
			inserted: (el) => {
				el.focus()
			}
		}
	}
};
</script>